import { createPortal } from 'react-dom';

import React, {
  useImperativeHandle,
  RefObject,
  useCallback,
  useMemo,
  memo,
  useRef,
} from 'react';

import TransitionInOut2, { TransitionInOut2Api } from '../TransitionInOut2';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { DIALOG_BOX_HEADER_HEIGHT } from './DialogBoxHeader';
import useKeyboard from '~/app/lib/hooks/useKeyboard';
import darkTheme from '~/app/lib/theme/dark';
import Scroller2 from '../Scroller2';
import Box from '../Box';

export const DIALOG_BOX_CONTENT_PADDING_X = '2rem';
export const DIALOG_BOX_CONTENT_PADDING_Y = '2.2rem';
export const DIALOG_BOX_MAX_HEIGHT = '40rem';

const TRANSITION_DURATION = 180;

export type CloseDialogBox<TParams = any> = (params?: TParams) => Promise<void>;

export interface DialogBoxApi<TCloseParams = any> {
  close: CloseDialogBox<TCloseParams>;
}

export type DialogBoxRenderHeader<TOnClose extends (params?: any) => void> =
  (params: {
    close: CloseDialogBox<Parameters<TOnClose>>;
  }) => JSX.Element | null;

export type DialogBoxRenderContent<TOnClose extends (params?: any) => void> =
  (params: {
    close: CloseDialogBox<Parameters<TOnClose>[0]>;
    paddingX: string;
    paddingY: string;
    isOverflowing: boolean;
  }) => JSX.Element | JSX.Element[] | null;

export interface DialogBoxProps<
  TOnClose extends (params?: any) => void = (params?: any) => void,
> {
  onClose: TOnClose;
  renderContent: DialogBoxRenderContent<TOnClose>;

  /**
   * An optional key used to refresh `useKeyboard()` so that
   * new content is processed and any `autoFocus` elements
   * are focused.
   */
  contentKey?: string;

  apiRef?: RefObject<DialogBoxApi>;

  /**
   * Render a fixed header that floats above the content.
   *
   * You must provide `headerHeight` also if your header isn't a <DialogBoxHeader>
   * so we can adjust content padding accordingly.
   *
   * You can alternatively wrap your header content in <Sticky> and place it
   * inside the content area if you need more control. But this isn't always
   * suitable if you have top-anchored sticky elements inside the DialogBox
   * content as they could push the header out of the scroll viewport.
   */
  renderHeader?: DialogBoxRenderHeader<TOnClose>;

  /**
   * Provide when renderHeader() content is not a <DialgoBoxHeader>
   */
  headerHeight?: string;

  fillViewport?: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  withPaddingX?: boolean;
  fillViewportOnSmallScreen?: boolean;
  withOverflowGradients?: boolean;
  testId?: string;
  zIndex?: number;
}

const _DialogBox = <TOnClose extends (...params: any[]) => void>({
  apiRef,
  onClose,
  renderContent,
  renderHeader,
  contentKey,
  headerHeight,
  fillViewport = false,
  maxHeight,
  maxWidth,
  testId,
  withPaddingX,
  withOverflowGradients = true,
  fillViewportOnSmallScreen,
  zIndex = 1,
}: DialogBoxProps<TOnClose>) => {
  const isLargeScreen = useIsLargeScreen();
  const transitionApiRef = useRef<TransitionInOut2Api>();
  const rootElRef = useRef<HTMLDivElement>(null);

  const close = useCallback<CloseDialogBox<any>>(
    async (param) => {
      await transitionApiRef.current?.setVisible(false);
      if (onClose) onClose(param);
    },
    [onClose]
  );

  useImperativeHandle(apiRef, () => ({
    close,
  }));

  useKeyboard(
    {
      rootElRef,
      onEscape: () => close(),
    },
    [contentKey]
  );

  if (fillViewportOnSmallScreen) {
    fillViewport = !isLargeScreen;
  }

  // prettier-ignore
  const contentPaddingTop =
    typeof headerHeight !== 'undefined'
      ? headerHeight
      : (renderHeader
      ? DIALOG_BOX_HEADER_HEIGHT
      : undefined);

  const Component = (
    <TransitionInOut2
      nodeRef={rootElRef}
      apiRef={transitionApiRef}
      duration={TRANSITION_DURATION}
      transitionOnMount
      coverParent
      centerContent
      tabIndex={0}
      className="dialogBox"
      padding={!fillViewport ? '2rem' : undefined}
      styleFrom={useMemo(
        () => ({
          transform: 'translateY(1.5rem)',
          opacity: 0,
        }),
        []
      )}
      zIndex={zIndex}
      role="dialog"
      aria-modal="true"
    >
      <Box coverParent onClick={useCallback(() => close(), [close])} />
      <Scroller2
        positionRelative
        isCentered
        fullHeight={fillViewport}
        fullWidth
        maxWidth={!fillViewport ? maxWidth ?? '42rem' : undefined}
        maxHeight={
          !fillViewport
            ? `min(${maxHeight ?? DIALOG_BOX_MAX_HEIGHT}, 90vh)`
            : undefined
        }
        renderBefore={useCallback(
          () => (renderHeader ? renderHeader({ close }) : null),
          [renderHeader]
        )}
        testId={testId}
        withOverflowGradients={withOverflowGradients}
        style={{
          // dialog text slightly off white - less garish
          color: '#f2f2f2',
          background: darkTheme.background,
          borderRadius: !fillViewport ? '.8rem' : undefined,
          border: !fillViewport ? `solid 1px #333` : undefined,

          boxShadow: !fillViewport
            ? `0 0 15rem #000,0 1rem 5rem rgba(0,0,0,.5)`
            : undefined,

          // prevent content overflowing rounded corners
          overflow: 'hidden',

          fontSize: '1rem',
        }}
        renderContent={useCallback(
          ({ contentContainerProps, isOverflowing }) => {
            return (
              <Box
                {...contentContainerProps}
                minHeight="100%"
                style={{
                  padding: withPaddingX
                    ? `0 ${DIALOG_BOX_CONTENT_PADDING_X}`
                    : undefined,
                  paddingTop: contentPaddingTop,
                }}
              >
                {renderContent({
                  close,
                  paddingX: DIALOG_BOX_CONTENT_PADDING_X,
                  paddingY: DIALOG_BOX_CONTENT_PADDING_Y,
                  isOverflowing,
                })}
              </Box>
            );
          },
          [renderContent, contentPaddingTop]
        )}
      />
      {/* ensure elements under the header scroll into view on tab */}
      <style jsx global>{`
        .dialogBox input {
          ${contentPaddingTop
            ? `scroll-margin: calc(${contentPaddingTop} * 1.1);`
            : ''}
        }
      `}</style>
    </TransitionInOut2>
  );

  return createPortal(Component, document.body);
};

const DialogBox = memo(_DialogBox) as unknown as typeof _DialogBox;

export default DialogBox;
