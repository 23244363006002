interface ToSizedImageUrlNextParams {
  url: string;
  width: number;
  height?: number;
  quality?: number;
  format?: 'jpeg' | 'webp';
  fit?: 'cover';
}

const toSizedImageUrlNext = ({
  url,
  width,
  height,
  quality = 60,
  format,
  fit,
}: ToSizedImageUrlNextParams) => {
  const options = formatOptions({ width, height, quality, format, fit });
  return `https://songwhip.com/cdn-cgi/image/${options}/${url}`;
};

const formatOptions = ({
  width,
  height,
  quality,
  format,
  fit,
}: Omit<ToSizedImageUrlNextParams, 'url'>) => {
  let options = `quality=${quality},width=${width}`;

  if (height) {
    options += `,height=${height}`;
  }

  if (format) {
    options += `,format=${format}`;
  }

  if (fit) {
    options += `,fit=${fit}`;
  }

  return options;
};

export default toSizedImageUrlNext;
