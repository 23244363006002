import { useState, useEffect, FC } from 'react';

import FadeOnMount from '../FadeOnMount';
import Loading from '../Loading';
import Box, { BoxProps } from '../Box';
import Text from '../Text';

interface PageLoadingProps extends BoxProps {
  text?: string;
  timeout?: number;
  size?: number | string;
}

const PageLoading: FC<PageLoadingProps> = ({
  timeout = 500,
  size = '4rem',
  text,
  style,
  ...boxProps
}) => {
  const [isVisible, setIsVisible] = useState(!timeout);

  useEffect(() => {
    if (!timeout) return;

    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <Box
      coverParent
      centerContent
      {...boxProps}
      style={{
        ...style,
        fontSize: size,
      }}
    >
      <FadeOnMount>
        <div>
          <Loading size="1em" />
          {text && (
            <Text margin={`.8em 0 0`} size=".45em" opacity={0.5}>
              {text}
            </Text>
          )}
        </div>
      </FadeOnMount>
    </Box>
  );
};

export default PageLoading;
