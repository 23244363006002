import { FC } from 'react';

import darkTheme from '~/app/lib/theme/dark';
import Text from '../Text';

const HeaderTitle: FC<{ text: string; color?: string; opacity?: number }> = ({
  text,
  color,
  opacity,
}) => (
  <Text
    centered
    size="1.45rem"
    isBold
    color={color || darkTheme.textColor40}
    opacity={opacity}
    fullHeight
    centerContent
    letterSpacing={0}
    lineHeight="1em"
    noSelection
  >
    {text}
  </Text>
);

export default HeaderTitle;
