import React, { FC } from 'react';

import ChevronIcon from '../Icon/ChevronIcon';

const HeaderBackButton: FC<{
  onClick: () => void;
  size?: string | number;
  testId?: string;
}> = ({ onClick, size, testId }) => (
  <ChevronIcon
    direction="left"
    onClick={onClick}
    margin="0 0 0 -0.3rem"
    color="#999"
    size={size}
    testId={testId}
  />
);

export default HeaderBackButton;
