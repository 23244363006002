import { useEffect, useMemo } from 'react';
import debounce from '../../utils/debounce';

const useDebounce = <T extends (...args: any[]) => void>(
  fn: T,
  ms: number,
  deps: any[] = []
) => {
  const debouncedFn = useMemo(() => {
    return debounce(fn, ms);
  }, deps);

  useEffect(() => {
    return () => {
      debouncedFn.clear();
    };
  }, [debouncedFn]);

  return debouncedFn;
};

export default useDebounce;
